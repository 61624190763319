.container-acercaDe {
  position: relative;
  margin-top: 20px;
  padding-bottom: 80px;
  top: 20px;
  background-color: var(--background);
}
.parrafos-acercaDe {
  font-family: "Archivo", sans-serif;
  margin-left: 5%;
  margin-right: 5%;
}
.video-iFrame {
  position: relative;
  width: 90%;
  left: 5%;
  min-height: 200px;
  margin: 10px 0;
}
.descripcion-video {
  position: relative;
  width: 100%;
  margin: 0;
  text-align: center;
  font-family: "Archivo", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

@media screen and (min-width: 600px) {
  .container-acercaDe {
    position: relative;
    width: 60%;
    left: 20%;
  }
  .video-iFrame {
    width: 560px;
    height: 315px;
    left: calc(50% - 280px);
    margin-top: 40px;
  }
  .descripcion-video {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 900px) {
  .container-acercaDe {
    position: relative;
    width: 50%;
    left: 25%;
  }
}
