@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;700&family=DM+Serif+Display&display=swap");
/*
font-family: 'DM Serif Display', serif;
font-family: 'Archivo', sans-serif;
*/
:root {
  --gris-nav: #d8d5d4;
  --marron-logo: #231b18;
  --icon-enabled: #7f4437;
  --icon-disabled: #848484;
  --background: #e8e3df;
  --background2: #e8e3df4d;
  --blanco: #fff;
  font-family: "DM Serif Display", serif;
  min-height: 150vh;
  position: relative;
  margin-bottom: 20px;
}

/*#root */

body {
  margin: 0;
  min-height: 100%;
  margin-bottom: 80px;
  background-color: var(--background);
}
.no-scroll {
  overflow: hidden;
}

/* .backgroundImage {
  position: fixed;
  top: 0px;
  height: 210%;
  opacity: 0.7;
  overflow: hidden;
  margin: 0px;
}
.backgroundImage img {
  width: auto;
  height: 50%;
  margin: 0px;
  padding: 0px;
} */
@media screen and (min-width: 600px) {
  /* .backgroundImage {
    height: 110%;
    width: auto;
  }
  .backgroundImage img {
    width: 100%;
    height: auto;
  } */
}

.ql-container {
  min-height: 80px;
}

a:hover,
button:hover {
  cursor: pointer;
}
