.nav-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: none;
  width: 100%;
  background-color: var(--gris-nav);
  height: 90px;
  padding: 0 15px;
  box-sizing: border-box;
  box-shadow: 0px 1px 11px 2px rgb(32, 32, 32);
  -webkit-box-shadow: 0px 1px 11px 2px rgb(32, 32, 32);
  -moz-box-shadow: 0px 1px 11px 2px rgb(32, 32, 32);
}

.logo-container {
  position: relative;
  top: 15px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.titulo-container {
  display: flex;
}
.navTitulo {
  /* display: none; */
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 33px 0 0 15px;
  
}
.navDir {
  display: block;
  font-size: 12px;
  line-height: 12px;
  margin: 0 0 0 15px;
}

.navButtons-container {
  /* position: relative; */
  position: absolute;
  background-color: var(--gris-nav);
  top: 90px;
  /* width: calc(100vw - 100px); */
  width: 100%;
  left: 0;
  margin-left: 0;
  height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;  
  /* opacity: 0; */
  transition: left  0.3s ease-in-out; 
  z-index: 900;
}

.navButtons-container--active {
  left: -100%;
  /* opacity: 1; */
}

.navButton {
  position: relative;
  width: auto;
  height: 50px;
}

.showsButton,
.cartaButton {
  width: auto;
}
.acercaDeButton {
  width: auto;
}
.FAQsButton {

}

.nav-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.nav-icon i {
  font-size: 30px;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.nav-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 35%;
  width: 100%;
  height: 30%;
}
.icon-text {
  margin: 0;
  color: var(--icon-disabled);
  font-size: 12px;
  font-weight: 700;
  font-family: "Archivo", sans-serif;
}
.icon-text.faq {
  margin-top: 9px;
  text-align: center;
}
.nav-notch {
  position: absolute;
  display: none;
  width: 30%;
  height: 5px;
  bottom: 0;
  background-color: var(--icon-enabled);
}
.nav-link i {
  color: var(--icon-disabled);
}
.nav-link.active i {
  color: var(--icon-enabled);
}
.nav-link.active p {
  color: var(--icon-enabled);
}
.nav-link.active .nav-notch {
  display: block;
}

/*button*/
.nav-toggle {
  display: block;
  padding: 47px 19px;
  width: 28px;
  height: 3px;
  border: 0;
  box-sizing: content-box;
  background-clip: content-box;
  transition: background-color 500ms ease-in-out 250ms;
  background-color: var(--marron-logo);
  
}
.nav-toggle:hover {
  cursor: pointer;
}

.nav-toggle:before,
.nav-toggle:after {
  position: relative;
  content: '';
  display: block;
  width: 28px;
  height: 3px;
  background-color: var(--marron-logo);
  transition: transform 500ms ease-in-out;
}

.nav-toggle:before {
  top: -10px;
}

.nav-toggle:after {
  bottom: -7px;
}

.nav-toggle__text {
  display: none;
}

.nav-toggle--active {
  background-color: rgba(255, 255, 255, 0);
  transition-delay: 0s;
}
.nav-toggle--active:before {
  transform: translateY(10px) rotate(-225deg);
}
.nav-toggle--active:after {
  transform: translateY(-10px) rotate(225deg);
}
@media screen and (min-width: 600px) {
  .titulo-container {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .tituloYDirContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .navTitulo {
    margin: 0 0 0 15px;
    display: block;
    white-space: nowrap;
    font-size: 30px;
    line-height: 30px;
  }
  .navDir {
    display: block;
    margin: 0 0 0 15px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 15px;
  }
  .logo-container {
    top: 0;
  }
  .navButtons-container {
    top: 0;
    position: relative;
    background-color: transparent;
    height: 90px;
    width: 40%;
    margin-left: 18px;
    flex-direction: row;
    justify-content: space-between;
  }
  .navButtons-container--active {
    left: 0;
    /* opacity: 1; */
  }
  /**/
  .nav-link {
    justify-content: center; 
  }
  .navButton {
    position: relative;
    width: 30%;
    height: 100%;
  }
  .showsButton,
  .cartaButton {
    width: 20%;
  }
  .acercaDeButton {
    width: 25%;
  }
  .FAQsButton {
    
  }
  .nav-notch {
    width: 60%;
  }
}
