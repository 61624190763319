.swiper {
    width: 100%;
    height: 100%;
    /* overflow: visible !important; */
    box-shadow: 0px 1px 11px 2px rgb(32, 32, 32);
    -webkit-box-shadow: 0px 1px 11px 2px rgb(32, 32, 32);
    -moz-box-shadow: 0px 1px 11px 2px rgb(32, 32, 32);
    box-shadow: 0px 1px -11px 2px rgb(32, 32, 32);
    -webkit-box-shadow: 0px 1px -11px 2px rgb(32, 32, 32);
    -moz-box-shadow: 0px 1px -11px 2px rgb(32, 32, 32);
    z-index: 500 !important;
    position: relative;
  }
  .swiper-button-prev {
    left: 50px !important;
    z-index: 500 !important;
    color: deepskyblue !important;
    filter: drop-shadow(3px 5px 12px deepskyblue);  
  }
  .swiper-button-next {
    right: 50px !important;
    z-index: 500 !important;
    color: deepskyblue !important;
    filter: drop-shadow(3px 5px 12px deepskyblue);
  }
 .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }

  .Marquesina__element {
    width: 100% !important;
  }
  .Marquesina__fecha-iconoSchedule {
    margin-top: 10px;
    margin-left: 5px;
    animation: neon 1s infinite linear;
    color: deepskyblue !important;
  }
  .Marquesina__fecha-texto {
    font-family: "Archivo", sans-serif;
    color: var(--icon-enabled);
    font-size: 14px;
    font-size: clamp(10px, 1vw, 14px);
    font-weight: bold;
    position: relative;
    display: inline;
    margin-left: 8px;
    color: deepskyblue !important;
    animation: neon 1s infinite linear;
  }
  .swiper-slide img {
    display: block;
    width: auto;
    height: 120px;
    object-fit: cover;
    margin: 20px;
  }

  .Marquesina__element h3 {
    color: white;
    margin: 0 5px !important;
    padding: 0;
    font-weight: 200;
    text-align: start;
    line-height: 10px;
    font-size: 26px;
  }

   .swiper-pagination {
    position: absolute;
    bottom: -30px !important;
    z-index: 400 !important;
   }
   .Marquesina__fecha-container {
    display: flex;
    justify-content: start;
    margin: 5px 0;
    align-items: center;
   }
   .Marquesina__fecha-iconoSchedule {
    color: var(--icon-enabled);
    position: relative;
    display: inline;
    margin-bottom: 15px;
    width: 24px;
    height: 24px;
}

.Marquesina__luces {
    position: absolute;
    top: 90px;
    z-index: 100 ;
    width: 100%;
    height: 160px;
    background-color: transparent;
    overflow: hidden;
    background: rgb(36,36,36);
    background: radial-gradient(circle, rgba(36,36,36,1) 0%, rgba(21,21,21,1) 17%, rgba(0,0,0,1) 100%);
}
.Marquesina__luces img {
  width: 100%;
  opacity: 0.1;
}
.Marquesina__luces-arriba {
  position: absolute;
  width: calc(100% - 12px);
  left: 4px;
  height: 6px;
  top: 0;
  display: flex;
  justify-content: space-between;
}
.Marquesina__luces-abajo {
  position: absolute;
  width: calc(100% - 12px);
  left: 4px;
  height: 6px;
  bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.Marquesina__luces-derecha {
  position: absolute;
  width: 14px;
  height: calc(100% - 50px);
  left: 3px;
  top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Marquesina__luces-izquierda {
  position: absolute;
  width: 14px;
  height: calc(100% - 50px);
  right: 0px;
  top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.foquito {
  position: relative;
  top: 6px;
  font-size: 30px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  animation: foquito 1s infinite linear;
  background-color: rgb(255, 248, 233);
  z-index: 900;
}
.foquito2 {
  position: relative;
  top: 0px;
  font-size: 30px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  animation: foquito 1s infinite linear;
  background-color: rgb(255, 248, 233);
  z-index: 900;
}
.Marquesina__luces-arriba > div:nth-child(odd) {
  width: 7px;
  height: 7px;
  top: 5px;
}
.Marquesina__luces-abajo > div:nth-child(even) {
  width: 7px;
  height: 7px;
  top: 5px;
}
.Marquesina__luces-izquierda > div:nth-child(odd) {
  width: 7px;
  height: 7px;
}
.Marquesina__luces-derecha > div:nth-child(even) {
  width: 7px;
  height: 7px;
}
.fecha-container {
  position: relative;
  margin-top: 10px;
  background-color: var(--gris-nav);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.fecha-iconoSchedule {
  color: var(--icon-enabled);
  position: relative;
  display: inline;
  left: 22px;
  width: 48px;
  height: 24px;
}
.fecha-texto {
  font-family: "Archivo", sans-serif;
  color: var(--icon-enabled);
  font-size: 14px;
  font-weight: bold;
  position: relative;
  display: inline;
  margin-left: 8px;
}
.fecha-button {
  position: absolute;
  height: 32px;
  padding: 0 14px;
  color: var(--background);
  font-weight: 700;
  background-color: var(--marron-logo);
  border-radius: 16px;
  border: none;
  right: 12px;
}

@keyframes foquito {
  0%,
  10% {
/*     color: rgb(255, 221, 142); */
    filter: drop-shadow(0px 0px 3px rgb(255, 221, 142));
  }
  /*  25% {
    color: deepskyblue;
    filter: drop-shadow(3px 5px 12px deepskyblue);
  }
  50% {
    color: lawngreen;
    filter: drop-shadow(3px 5px 12px lawngreen);
  }
  75% {
    color: yellow;
    filter: drop-shadow(3px 5px 12px yellow);
  } */
  70% {
 /*    color: rgb(255, 221, 142); */
    filter: drop-shadow(0px 0px 3px rgb(255, 221, 142));
  }
  80% {
    color: black;
  }
}


@keyframes neon {
  0%,
  10% {
    color: deepskyblue;

  }
   25% {
    color: deepskyblue;
    filter: drop-shadow(1px 2px 5px rgba(0, 191, 255, 0.4));
  }
  50% {
    color: deepskyblue;
    filter: drop-shadow(1px 2px 5px rgba(0, 191, 255, 0.4));
  }
  75% {
    color: deepskyblue;
    filter: drop-shadow(1px 2px 5px rgba(0, 191, 255, 0.4));
  }
  70% {
    color: deepskyblue;
 
  }
  80% {
    color: black;
  }
}

@media screen and (max-width: 600px) {
  .Marquesina__element-titulo {
    font-size: clamp(14px, 5vw, 40px) !important;
    line-height: clamp(16px, 4vw, 40px) !important;
    
  }
  .Marquesina__element-textContainer {
    margin-right: 20px;
    margin-top: 20px !important;
  }
  .Marquesina__fecha-iconoSchedule {
    margin-top: 15px;
    margin-left: 0px;
  }
  .fecha-texto {
    margin-left: 10px !important;
  }
  .swiper {
    height: 160px;
  }
}
@media screen and (max-width: 400px) {
  .Marquesina__element-titulo {
    margin: 0px;
  }
  .fecha-texto {
    margin-left: 5px !important;
  }
}
@media screen and (max-width: 340px) {

  .fecha-texto {
    margin-left: 0px !important;
  }
}